// App.tsx

import React, { useState, useEffect } from 'react';
import PoseGame from './PoseGame';
import CircularControl from './CircularControl';
import FrequencyTimer from './FrequencyTimer';
import './App.css';

// Instead of creating AudioContext immediately, create it after user interaction
let audioContext: AudioContext | null = null;

// Move the check outside the component
const isMobileOrTablet = () => {
  // Check if running in browser environment
  if (typeof window === 'undefined') return false;
  
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) || window.innerWidth <= 768;
};

function App() {
  // Use the function in useState
  const [isSmallScreen] = useState(isMobileOrTablet());

  

  const [showWelcome, setShowWelcome] = useState(true);

  // Adjust this to test game itself
  const [showGame, setShowGame] = useState(false);

  const [sessionLength, setSessionLength] = useState(2); // in minutes
  const [frequency, setFrequency] = useState(25); // in minutes
  const [musclePercentage, setMusclePercentage] = useState(0); // Placeholder

  const [frequencyTimeLeft, setFrequencyTimeLeft] = useState(frequency * 60); // Frequency timer in seconds

  const [completedMovements, setCompletedMovements] = useState(() => {
    // Initialize with today's movements from localStorage
    const today = new Date().toISOString().split('T')[0];
    return parseInt(localStorage.getItem(`movements_${today}`) || '0');
  });

  // Add this useEffect to update frequencyTimeLeft when frequency changes
  useEffect(() => {
    setFrequencyTimeLeft(frequency * 60);
  }, [frequency]);

  // const [showConsentModal, setShowConsentModal] = useState(false);
  // const [isFirstVisit, setIsFirstVisit] = useState(() => {
  //   return localStorage.getItem('hasVisitedBefore') !== 'true';
  // });

 


  // Add new state for modal
  const [showTimerModal, setShowTimerModal] = useState(false);

  const initializeAudio = () => {
    if (!audioContext) {
      audioContext = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }
  };

  const playChime = () => {
    if (!audioContext) return;
    
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();
    
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);
    
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(880, audioContext.currentTime);
    
    gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.currentTime + 0.5);
    
    oscillator.start(audioContext.currentTime);
    oscillator.stop(audioContext.currentTime + 0.5);
  };

  const handleStartSession = () => {
    initializeAudio(); // Initialize audio when user starts session
    setShowWelcome(false);
    setShowTimerModal(false);
    setShowGame(true);


    setFrequencyTimeLeft(frequency * 60);
  };

  const handleGameCompletion = (newMovements: number) => {
    const today = new Date().toISOString().split('T')[0];
    const updatedMovements = completedMovements + newMovements;
    
    // Update localStorage
    localStorage.setItem(`movements_${today}`, updatedMovements.toString());
    
    // Update state
    setCompletedMovements(updatedMovements);

    
    // Reset the game
    setShowGame(false);
    setShowWelcome(true);
  };

  const handleTimerComplete = () => {
    if (audioContext?.state === 'running') {
      playChime();
    }
    
    console.log('timer complete');
    setShowTimerModal(true);
  };

  // Add new handler for Skip action
  
  const handleSkip = () => {
    setShowTimerModal(false);
    setFrequencyTimeLeft(frequency * 60);
    // Add a small delay to prevent immediate timer completion
    // setTimeout(() => {
    //   setFrequencyTimeLeft(frequency * 60);
    // }, 100);
  };

  // For debugging, add this useEffect
  useEffect(() => {
    console.log('Frequency time left:', frequencyTimeLeft);
  }, [frequencyTimeLeft]);

  // Early return if on mobile/tablet
  if (isSmallScreen) {
    return (
      <div className="mobile-message">
        <h1>Sorry, desktop only.</h1>
        <p>MotionBreak requires a good sized screen and well positioned camera.</p>
        <p>It displays large text, meant to be read from a distance.</p>
        <p>It's best to use while working, to help you remember to move.</p>
      </div>
    );
  }

  return (
    <div className="app">
      
      {showWelcome && (
        <div className="welcome-screen">
          <h1 style={{ fontFamily: 'logo' }}>Motion Break!</h1>
          <CircularControl
            sessionLength={sessionLength}
            setSessionLength={setSessionLength}
            frequency={frequency}
            setFrequency={setFrequency}
            completedMovements={completedMovements}
          />
        </div>
      )}
      {showGame && (
        <PoseGame
          onGameComplete={handleGameCompletion}
          sessionLength={sessionLength}
        />
      )}

      {/* Add modal */}
      {showTimerModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h1>Motion break!</h1>
            <div className="modal-buttons">
              <button onClick={handleSkip}>Skip</button>
              <button onClick={handleStartSession}>Begin</button>
            </div>
          </div>
        </div>
      )}

      {!showGame && ( 
        <FrequencyTimer 
          timeLeft={frequencyTimeLeft}
          onStartNow={handleStartSession}
          onTimerComplete={handleTimerComplete}
        />
      )}
    </div>
  );
}

export default App;
